/* eslint-disable no-console */
import css from './ApiDocsSection.module.scss'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import axios from 'axios'
import { useEffect, useState } from 'react'

const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export const ApiDocsSection = ({
  merchant,
  title,
  method,
  path,
  desc,
  searchParams,
  bodyParams,
  hashParams,
  responseSchema,
  responseExample,
}: {
  title: string
  desc?: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  path: string
  searchParams?: Array<{ key: string; title: string; desc: string }>
  bodyParams?: Array<{ key: string; title: string; desc: string; defaultValue?: string }>
  hashParams?: string[]
  responseSchema?: Array<{ key: string; title?: string; desc?: string; type?: string }>
  responseExample?: Record<string, any>
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  const ctx = useAppContext()
  const [responseData, setResponseData] = useState<{
    status: null | number
    data: Record<string, any>
  }>({
    status: null,
    data: {},
  })
  const formy = useFormy({
    initialValues: {
      apiKey: merchant?.apiKey || '',
      apiSecret: merchant?.apiSecret || '',
      hashValue: '',
      searchParams: (searchParams?.reduce((acc, { key }) => ({ ...acc, [key]: '' }), {}) || {}) as Record<
        string,
        string
      >,
      bodyParams: (bodyParams?.reduce((acc, { key, defaultValue }) => ({ ...acc, [key]: defaultValue || '' }), {}) ||
        {}) as Record<string, string>,
    },
    onSubmit: async ({ valuesInput }) => {
      const values = valuesInput
      const searchParams = new URLSearchParams(values.searchParams)
      const searchString = searchParams.toString()
      const searchStringWithQuestionMark = searchString ? '?' + searchString : ''
      const headers = {
        'Content-Type': 'application/json',
        'X-Api-Key': values.apiKey,
        ...(values.hashValue && { 'X-Api-Hash': values.hashValue }),
      }
      try {
        const response = await axios({
          method,
          url: ctx.env.VITE_BACKEND_URL + path + searchStringWithQuestionMark,
          data: values.bodyParams,
          headers,
        })
        setResponseData({
          status: response.status,
          data: response.data,
        })
      } catch (error: any) {
        setResponseData({
          status: error.response.status,
          data: error.response.data, //
        })
      }
    },
    messagePolicy: 'informer',
  })
  useEffect(() => {
    if (hashParams?.length) {
      void (async () => {
        try {
          const initialString =
            formy.values.apiSecret + '|' + hashParams.map((key) => formy.values.bodyParams[key] || '').join('|')
          const hashValue = await sha256(initialString)
          void formy.setFieldValue('hashValue', hashValue)
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [JSON.stringify(formy.values)])

  return (
    <form className={css.section} {...formy.formProps}>
      <Segment title={title} size="l">
        <div className={css.url}>
          {method} {ctx.env.VITE_BACKEND_URL}
          {path}
        </div>
        {desc && <div className={css.desc}>{desc}</div>}

        {responseSchema && (
          <div className={css.responseSchema}>
            <h3 className={css.responseSchemaTitle}>Схема ответа сервера</h3>
            <div className={css.responseSchemaSelf}>
              {responseSchema?.map((rsi) => (
                <div key={rsi.key} className={css.responseSchemaItem}>
                  {rsi.key && <div className={css.responseSchemaItemKey}>Ключ: {rsi.key}</div>}
                  {rsi.title && <div className={css.responseSchemaItemTitle}>Название: {rsi.title}</div>}
                  {rsi.desc && <div className={css.responseSchemaItemDesc}>Описание: {rsi.desc}</div>}
                  {rsi.desc && <div className={css.responseSchemaItemType}>Тип: {rsi.desc}</div>}
                </div>
              ))}
            </div>
          </div>
        )}
        {responseExample && (
          <div className={css.responseExample}>
            <h3 className={css.responseExampleTitle}>Пример ответа сервера</h3>
            <pre className={css.responseExampleSelf}>{JSON.stringify(responseExample, null, 2)}</pre>
          </div>
        )}
        {searchParams?.length ? (
          <div className={css.params}>
            <h3 className={css.paramsTitle}>Search параметры запроса</h3>
            <FormItems>
              {searchParams.map(({ key, title, desc }) => (
                <Textfieldy
                  key={`searchParams.${key}`}
                  {...formy.getFieldProps(`searchParams.${key}`)}
                  label={title}
                  hint={desc}
                  $style={{
                    labelColor: 'var(--mantine-color-text)',
                  }}
                />
              ))}
            </FormItems>
          </div>
        ) : null}
        {bodyParams?.length ? (
          <div className={css.params}>
            <h3 className={css.paramsTitle}>Body параметры запроса</h3>
            <FormItems>
              {bodyParams.map(({ key, title, desc }) => (
                <Textfieldy
                  key={`bodyParams.${key}`}
                  {...formy.getFieldProps(`bodyParams.${key}`)}
                  label={title}
                  hint={desc}
                  $style={{
                    labelColor: 'var(--mantine-color-text)',
                  }}
                />
              ))}
            </FormItems>
          </div>
        ) : null}
        <div className={css.params}>
          <h3 className={css.paramsTitle}>Заголовки запроса</h3>
          <FormItems>
            <Textfieldy
              {...formy.getFieldProps('apiKey')}
              label="X-Api-Key"
              hint="Значение API ключа вы найдёте на странице API в мерчантской"
              $style={{
                labelColor: 'var(--mantine-color-text)',
              }}
            />
            {hashParams?.length && (
              <Textfieldy
                {...formy.getFieldProps('apiSecret')}
                label="API секрет (не передаётся в заголовке!)"
                hint="Никогда не передаётся нам на сервер! Здесь это поле указано только для того, чтобы прямо в браузере посчитать значение для X-Api-Hash ниже. Значение API секрета вы найдёте на странице API в мерчантской."
                $style={{
                  labelColor: 'var(--mantine-color-text)',
                }}
              />
            )}
            {hashParams?.length && (
              <Textfieldy
                {...formy.getFieldProps(`hashValue`)}
                label="X-Api-Hash"
                hint={`Нужно для проверки и высчитывается как sha256(apiSecret + "|" + ${hashParams.join(' + "|" + ')}). Если какой-то элемент не был передан, вместо него должна быть пустая строка. Напрмиер sha256(... + "|" + "" + "|" + ...)`}
                $style={{
                  labelColor: 'var(--mantine-color-text)',
                }}
              />
            )}
          </FormItems>
        </div>
        <div className={css.form}>
          <FormItems>
            {/* TODO */}
            {/* <Informer {...formy.informerProps} /> */}
            {!formy.informerProps.hidden && <pre>{JSON.stringify(formy.informerProps, null, 2)}</pre>}
            <Buttons>
              <Button {...formy.buttonProps} type="submit">
                Отправить запрос
              </Button>
            </Buttons>
          </FormItems>
        </div>
        {responseData.status !== null && (
          <div className={css.response}>
            <h3 className={css.responseTitle}>Реальный ответ сервера</h3>
            <h3 className={css.responseStatus}>Статус: {responseData.status}</h3>
            <pre className={css.repsonseSelf}>{JSON.stringify(responseData.data, null, 2)}</pre>
          </div>
        )}
        <div className={css.codeExample}>
          <h3 className={css.codeExampleTitle}>Пример кода на PHP</h3>
          <pre className={css.codeExampleSelf}>{`<?php
  $apiKey = '${formy.values.apiKey || 'YOUR_API_KEY'}';${
    !hashParams?.length
      ? ''
      : `
  $apiSecret = '${formy.values.apiSecret || 'YOUR_API_SECRET'}';`
  }
  ${
    !searchParams?.length
      ? ''
      : `
  $searchParams = [
    ${searchParams?.map((sp) => `'${sp.key}' => $_POST['${sp.key}'] ?? ''`).join(',\n    ')}
  ];`
  }${
    !bodyParams?.length
      ? ''
      : `
  $bodyParams = [
    ${bodyParams?.map((bp) => `'${bp.key}' => $_POST['${bp.key}'] ?? ''`).join(',\n    ')}
  ];
  `
  }${
    !hashParams?.length
      ? ''
      : `
  $hashString = $apiSecret 
    . '|' . ${hashParams.map((hp) => `$bodyParams['${hp}']`).join("\n    . '|' . ")};
  $hashValue = hash('sha256', $hashString);
  `
  }
  $headers = [
    'Content-Type: application/json',
    'X-Api-Key: ' . $apiKey${
      !hashParams?.length
        ? ''
        : `,
    'X-Api-Hash: ' . $hashValue`
    }
  ];
  ${
    !searchParams?.length
      ? `
  $url = '${ctx.env.VITE_BACKEND_URL}${path}';`
      : `
  $url = '${ctx.env.VITE_BACKEND_URL}${path}?' . http_build_query($searchParams);
  `
  }
  $ch = curl_init($url);${
    method === 'GET'
      ? `
  curl_setopt($ch, CURLOPT_HTTPGET, true);`
      : method === 'POST'
        ? `
  curl_setopt($ch, CURLOPT_POST, true);`
        : ``
  }
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);${
    !bodyParams?.length
      ? ''
      : `
  curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($bodyParams));`
  }

  $response = curl_exec($ch);
  $httpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

  curl_close($ch);

  if ($httpCode === 200) {
    echo 'Response: ' . $response;
  } else {
    echo 'Error: ' . $response;
  }
?>
`}</pre>
        </div>
      </Segment>
    </form>
  )
}
