/* eslint-disable react/forbid-component-props */
import classes from './index.module.css'
import {
  UnresolvedChatsCounter,
  useUnresolvedChatsCounter,
} from '@/general/src/chat/components/UnresolvedChatsCounter/index.js'
import { layoutStorik } from '@/webapp/src/components/layout/store.js'
import { NavLink } from '@/webapp/src/components/other/NavLink/index.js'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import {
  adminActionLogsRoute,
  adminAdminListRoute,
  adminChatsRoute,
  adminCompanySettingsRoute,
  adminCustomerDepositListRoute,
  adminCustomerWithdrawListRoute,
  adminDashboardRoute,
  adminMerchantDepositListRoute,
  adminMerchantListRoute,
  adminMerchantWithdrawListRoute,
  adminSettingsRoute,
  adminSignInRoute,
  adminSignOutRoute,
  adminTraderDepositListRoute,
  adminTraderInsuranceDepositListRoute,
  adminTraderInsuranceWithdrawListRoute,
  adminTraderListRoute,
  adminTraderWithdrawListRoute,
  adminTxsRoute,
  apiDocsRoute,
  generalDocsRoute,
  homeRoute,
  merchantApiRoute,
  merchantChatsRoute,
  merchantCustomerDepositListRoute,
  merchantCustomerWithdrawListRoute,
  merchantDashboardRoute,
  merchantMemberSettingsRoute,
  merchantMerchantDepositListRoute,
  merchantMerchantMemberListRoute,
  merchantMerchantWithdrawListRoute,
  merchantSettingsRoute,
  merchantSignInRoute,
  merchantSignOutRoute,
  merchantTxsRoute,
  merchantWebhooksRoute,
  traderAutomatorRoute,
  traderChatsRoute,
  traderCustomerDepositListRoute,
  traderCustomerWithdrawListRoute,
  traderDashboardRoute,
  traderSettingsRoute,
  traderSignInRoute,
  traderSignOutRoute,
  traderTraderDepositListRoute,
  traderTraderInsuranceDepositListRoute,
  traderTraderInsuranceWithdrawListRoute,
  traderTraderWithdrawListRoute,
  traderTxsRoute,
  webhooksDocsRoute,
} from '@/webapp/src/lib/routes.js'
import { Block, ControlIcon, Disabler, Layout, Text } from '@/webapp/src/lib/uninty.components.js'
import { useWindowSize } from '@/webapp/src/lib/windowSize.js'
import { Switch, useComputedColorScheme,useMantineColorScheme } from '@mantine/core'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Menu = () => {
  const { me } = useAppContext()
  const unresolvedChatsCounterStore = useUnresolvedChatsCounter()
  return (
    <Block fcnw afs>
      <Block fcnw mb={20}>
        <Block fcnw g={10} afs>
          <NavLink variant="body-l" href={homeRoute.get()}>
            Главная страница
          </NavLink>
          <NavLink variant="body-l" href={generalDocsRoute.get()}>
            Документация по сути
          </NavLink>
          <NavLink variant="body-l" href={apiDocsRoute.get()}>
            Документация по API
          </NavLink>
          <NavLink variant="body-l" href={webhooksDocsRoute.get()}>
            Документация по вебхукам
          </NavLink>
          {!me.trader && (
            <NavLink variant="body-l" href={traderSignInRoute.get()}>
              Вход в трейдерскую
            </NavLink>
          )}
          {!me.merchantMember && (
            <NavLink variant="body-l" href={merchantSignInRoute.get()}>
              Вход в мерчантскую
            </NavLink>
          )}
          {!me.admin && (
            <NavLink variant="body-l" href={adminSignInRoute.get()}>
              Вход в админку
            </NavLink>
          )}
        </Block>
      </Block>
      {me.trader && (
        <Block fcnw mb={20} g={10}>
          <Text variant="body-s" weight="bold">
            Трейдерская
          </Text>
          <Block fcnw g={10} afs>
            <>
              <NavLink variant="body-l" href={traderDashboardRoute.get()}>
                Дашборд
              </NavLink>
              <NavLink variant="body-l" href={traderChatsRoute.get()}>
                Чаты
                {!!unresolvedChatsCounterStore.trader.chatsCount &&
                  ` (${unresolvedChatsCounterStore.trader.chatsCount})`}
              </NavLink>
              <NavLink variant="body-l" href={traderTxsRoute.get()}>
                Транзакции
              </NavLink>
              <NavLink variant="body-l" href={traderCustomerWithdrawListRoute.get()}>
                Клиентские виздравы
              </NavLink>
              <NavLink variant="body-l" href={traderCustomerDepositListRoute.get()}>
                Клиентские депозиты
              </NavLink>
              <NavLink variant="body-l" href={traderTraderWithdrawListRoute.get()}>
                Трейдерские виздравы
              </NavLink>
              <NavLink variant="body-l" href={traderTraderDepositListRoute.get()}>
                Трейдерские депозиты
              </NavLink>
              <NavLink variant="body-l" href={traderTraderInsuranceWithdrawListRoute.get()}>
                Трейдерские страховые виздравы
              </NavLink>
              <NavLink variant="body-l" href={traderTraderInsuranceDepositListRoute.get()}>
                Трейдерские страховые депозиты
              </NavLink>
              <NavLink variant="body-l" href={traderAutomatorRoute.get()}>
                Автоматика
              </NavLink>
              <NavLink variant="body-l" href={traderSettingsRoute.get()}>
                Настройки
              </NavLink>
              <NavLink variant="body-l" href={traderSignOutRoute.get()}>
                Выход
              </NavLink>
            </>
          </Block>
        </Block>
      )}
      {me.merchantMember && (
        <Block fcnw mb={20} g={10}>
          <Text variant="body-s" weight="bold">
            Мерчантская
          </Text>
          <Block fcnw g={10} afs>
            <>
              <NavLink variant="body-l" href={merchantDashboardRoute.get()}>
                Дашборд
              </NavLink>
              <NavLink variant="body-l" href={merchantChatsRoute.get()}>
                Чаты
                {!!unresolvedChatsCounterStore.merchantMember.chatsCount &&
                  ` (${unresolvedChatsCounterStore.trader.chatsCount})`}
              </NavLink>
              <NavLink variant="body-l" href={merchantTxsRoute.get()}>
                Транзакции
              </NavLink>
              <NavLink variant="body-l" href={merchantCustomerWithdrawListRoute.get()}>
                Клиентские виздравы
              </NavLink>
              <NavLink variant="body-l" href={merchantCustomerDepositListRoute.get()}>
                Клиентские депозиты
              </NavLink>
              <NavLink variant="body-l" href={merchantMerchantWithdrawListRoute.get()}>
                Мерчантские виздравы
              </NavLink>
              <NavLink variant="body-l" href={merchantMerchantDepositListRoute.get()}>
                Мерчантские депозиты
              </NavLink>
              <NavLink variant="body-l" href={merchantMerchantMemberListRoute.get()}>
                Сотрудники
              </NavLink>
              <NavLink variant="body-l" href={merchantSettingsRoute.get()}>
                Настройки мерчанта
              </NavLink>
              <NavLink variant="body-l" href={merchantMemberSettingsRoute.get()}>
                Мои настройки
              </NavLink>
              <NavLink variant="body-l" href={merchantApiRoute.get()}>
                API
              </NavLink>
              <NavLink variant="body-l" href={merchantWebhooksRoute.get()}>
                Вебхуки
              </NavLink>
              <NavLink variant="body-l" href={merchantSignOutRoute.get()}>
                Выход
              </NavLink>
            </>
          </Block>
        </Block>
      )}
      {me.admin && (
        <Block fcnw mb={20} g={10}>
          <Text variant="body-s" weight="bold">
            Админка
          </Text>
          <Block fcnw g={10} afs>
            <NavLink variant="body-l" href={adminDashboardRoute.get()}>
              Дашборд
            </NavLink>
            <NavLink variant="body-l" href={adminChatsRoute.get()}>
              Чаты
              {!!unresolvedChatsCounterStore.admin.chatsCount && ` (${unresolvedChatsCounterStore.admin.chatsCount})`}
            </NavLink>
            <NavLink variant="body-l" href={adminTxsRoute.get()}>
              Транзакции
            </NavLink>
            <NavLink variant="body-l" href={adminActionLogsRoute.get()}>
              Логи
            </NavLink>
            <NavLink variant="body-l" href={adminCustomerWithdrawListRoute.get()}>
              Клиентские виздравы
            </NavLink>
            <NavLink variant="body-l" href={adminCustomerDepositListRoute.get()}>
              Клиентские депозиты
            </NavLink>
            <NavLink variant="body-l" href={adminTraderWithdrawListRoute.get()}>
              Трейдерские виздравы
            </NavLink>
            <NavLink variant="body-l" href={adminTraderDepositListRoute.get()}>
              Трейдерские депозиты
            </NavLink>
            <NavLink variant="body-l" href={adminTraderInsuranceWithdrawListRoute.get()}>
              Трейдерские страховые виздравы
            </NavLink>
            <NavLink variant="body-l" href={adminTraderInsuranceDepositListRoute.get()}>
              Трейдерские страховые депозиты
            </NavLink>
            <NavLink variant="body-l" href={adminMerchantWithdrawListRoute.get()}>
              Мерчантские виздравы
            </NavLink>
            <NavLink variant="body-l" href={adminMerchantDepositListRoute.get()}>
              Мерчантские депозиты
            </NavLink>
            <NavLink variant="body-l" href={adminMerchantListRoute.get()}>
              Мерчанты
            </NavLink>
            <NavLink variant="body-l" href={adminTraderListRoute.get()}>
              Трейдеры
            </NavLink>
            <NavLink variant="body-l" href={adminAdminListRoute.get()}>
              Админы
            </NavLink>
            <NavLink variant="body-l" href={adminSettingsRoute.get()}>
              Мои настройки
            </NavLink>
            <NavLink variant="body-l" href={adminCompanySettingsRoute.get()}>
              Настройки компании
            </NavLink>
            <NavLink variant="body-l" href={adminSignOutRoute.get()}>
              Выход
            </NavLink>
          </Block>
        </Block>
      )}
    </Block>
  )
}

export const GeneralLayout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const { loading, modalOpened } = layoutStorik.useStore()
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < 1_000

  useEffect(() => {
    if (modalOpened) {
      layoutStorik.updateStore({ modalOpened: false })
    }
  }, [pathname])

  const { setColorScheme } = useMantineColorScheme()
  const computedColorScheme = useComputedColorScheme('dark', { getInitialValueInEffect: true })

  return (
    <>
      <UnresolvedChatsCounter />
      <Layout
        $style={{
          headerFixed: true,
          sidebarFixed: true,
          headerBackground: 'var(--mantine-color-body) !important',
          bodyBackground: 'var(--mantine-color-body) !important',
          sidebarBackgroundColor: 'var(--mantine-color-body) !important',
          footerBackground: 'var(--mantine-color-body) !important',
          modalBackground: 'var(--mantine-color-body) !important',
        }}
        colorMode={computedColorScheme}
        headerRender={
          <Block frnw ac hf jsb>
            <Text variant="heading-m">PayHub</Text>

            <Switch
              label="Темная тема"
              className={classes.themeSwitch}
              size="md"
              onClick={() => setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark')}
              value={computedColorScheme}
              checked={computedColorScheme === 'dark'}
            />

            {isMobile && (
              <ControlIcon
                name={modalOpened ? 'closeTimes' : 'menuBurger'}
                onClick={() => {
                  layoutStorik.updateStore({ modalOpened: !modalOpened })
                }}
              />
            )}
          </Block>
        }
        sidebarRender={
          isMobile ? null : (
            <>
              <Menu />
              <br />
              <br />
            </>
          )
        }
      >
        <Disabler
          disabled={loading}
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            flex: '1 1 100%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: isMobile && modalOpened ? 'flex' : 'none',
              flexFlow: 'column nowrap',
              alignItems: 'stretch',
              justifyContent: 'stretch',
              flex: '1 1 100%',
              width: '100%',
            }}
          >
            <Menu />
          </div>
          <div
            style={{
              display: isMobile && modalOpened ? 'none' : 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'stretch',
              justifyContent: 'stretch',
              flex: '1 1 100%',
              width: '100%',
            }}
          >
            {children}
          </div>
        </Disabler>
      </Layout>
    </>
  )
}
